// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LocationsPage::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  .LocationsPage::-webkit-scrollbar:vertical {
    width: 14px;
  }
  .LocationsPage::-webkit-scrollbar:horizontal {
    height: 14px;
  }
  .LocationsPage::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 10px;
    border: 2px solid #ffffff;
  }
  .LocationsPage::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }


  `, "",{"version":3,"sources":["webpack://./src/components/Map/LocationsPage.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;EAC1B;EACA;IACE,WAAW;EACb;EACA;IACE,YAAY;EACd;EACA;IACE,mCAAmC;IACnC,mBAAmB;IACnB,yBAAyB;EAC3B;EACA;IACE,mBAAmB;IACnB,yBAAyB;EAC3B","sourcesContent":[".LocationsPage::-webkit-scrollbar {\n    -webkit-appearance: none;\n  }\n  .LocationsPage::-webkit-scrollbar:vertical {\n    width: 14px;\n  }\n  .LocationsPage::-webkit-scrollbar:horizontal {\n    height: 14px;\n  }\n  .LocationsPage::-webkit-scrollbar-thumb {\n    background-color: rgba(0, 0, 0, .5);\n    border-radius: 10px;\n    border: 2px solid #ffffff;\n  }\n  .LocationsPage::-webkit-scrollbar-track {\n    border-radius: 10px;\n    background-color: #ffffff;\n  }\n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
