import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { Box, Typography, Paper, Divider, Chip, useTheme } from "@mui/material";
import { BsKeyFill } from "react-icons/bs";

function RoomViewAppointments(props) {
  const theme = useTheme();

  const makeCredentialRow = (credential, sentLink) => {
    const { start_date_time, end_date_time } = credential;

    const color = sentLink === true
      ? theme.palette.success.main
      : sentLink === false
        ? theme.palette.error.main
        : theme.palette.text.secondary;

    const label = sentLink === true
      ? "TRUE"
      : sentLink === false
        ? "FALSE"
        : "Not Yet";

    return (
      <Paper
        variant="outlined"
        sx={{ p: 2, mt: 2, bgcolor: theme.palette.background.paper }}
      >
        <Box display="flex" alignItems="center" gap={1} mb={1}>
          <BsKeyFill />
          <Typography variant="subtitle1" fontWeight="bold">Credential</Typography>
        </Box>

        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography>Start: {moment(start_date_time).format("h:mm a")}</Typography>
          <Typography>End: {moment(end_date_time).format("h:mm a")}</Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        <Typography variant="body2">Sent Access Text:</Typography>
        <Typography fontWeight="medium" color={color}>
          {label}
        </Typography>
      </Paper>
    );
  };

  const matchCredential = (id, credentials, sentAccessLinkText) => {
    for (const credential of credentials) {
      if (credential.appointment_id === id) {
        return makeCredentialRow(credential, sentAccessLinkText);
      }
    }
    return null;
  };

  const mapAppointments = (appointments, credentials) => {
    return appointments.map((appointment, i) => {
      const {
        appointment_id,
        client_first_name,
        start_date_time,
        end_date_time,
        red_light,
        sent_access_link_text,
        towel,
      } = appointment;

      return (
        <Paper
          key={i}
          variant="outlined"
          sx={{ mb: 3, p: 2, bgcolor: theme.palette.background.default }}
        >
          <Box display="flex" flexDirection="column" gap={1}>
            <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
              <Typography variant="h6" fontWeight="bold">
                {client_first_name}
              </Typography>
              <Box display="flex" gap={1} alignItems="center" flexWrap="wrap">
                <Typography>{moment(start_date_time).format("dddd")}</Typography>
                <Typography>{moment(start_date_time).format("h:mm a")}</Typography>
                <Typography>-</Typography>
                <Typography>{moment(end_date_time).format("h:mm a")}</Typography>
                <Typography>{moment(start_date_time).format("(MM/DD/YYYY)")}</Typography>
              </Box>
            </Box>

            <Divider sx={{ my: 1 }} />

            <Box display="flex" gap={2} flexWrap="wrap">
              <Chip
                label={`Red Light: ${red_light ? "True" : "False"}`}
                color={red_light ? "primary" : "warning"}
                variant="outlined"
              />
              {towel && (
                <Chip
                  label="Towel: True"
                  color="info"
                  variant="outlined"
                />
              )}
            </Box>

            {matchCredential(appointment_id, credentials, sent_access_link_text)}
          </Box>
        </Paper>
      );
    });
  };

  return (
    <Paper>
      <Box sx={{ p: 2, m: 2 }}>
        <Typography
          variant="h5"
          fontWeight="bold"
          gutterBottom
          color="text.primary"
        >
          Room Appointments and Credentials
        </Typography>

        {props.appointments?.length > 0 ? (
          <Box>{mapAppointments(props.appointments, props.credentials)}</Box>
        ) : (
          <Typography>No appointments available.</Typography>
        )}
      </Box>
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  reduxState: state,
});

export default connect(mapStateToProps)(RoomViewAppointments);
