// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LocationsMapWrapper {
  min-height: 90vh;
}

.Locations {
  display: flex;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}

.LocationsMobile {
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}

#LocationMapFull {
  max-width: 800px;
  animation: reveal 1s forwards;
  margin-left: auto;
  margin-right: auto;
}

@keyframes reveal {
  from {
    clip-path: inset(0 0 0 100%);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.FullLocationsList {
  width: 100%;
  max-width: 600px;
  margin-left: 10%;
  min-height: 40vh;
}

.FullLocationsTitle {
  text-align: left;
  margin-top: 5%;
  margin-left: 5%;
}

.locations-side-menu {
  height: fit-content;
  width: 50%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.locations-side-menu-mobile {
  width: 100%;
  margin-bottom: 5%;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
}

.location-address-group {
  display: flex;
  justify-content: space-between;
  margin-right: 10%;
  margin-bottom: 4%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Map/Locations.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,6BAA6B;EAC7B,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE;IACE,4BAA4B;EAC9B;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,UAAU;EACV,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,cAAc;EACd,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,iBAAiB;EACjB,iBAAiB;AACnB","sourcesContent":[".LocationsMapWrapper {\n  min-height: 90vh;\n}\n\n.Locations {\n  display: flex;\n  max-width: 1600px;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.LocationsMobile {\n  display: flex;\n  flex-direction: column;\n  max-width: 1600px;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n#LocationMapFull {\n  max-width: 800px;\n  animation: reveal 1s forwards;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n@keyframes reveal {\n  from {\n    clip-path: inset(0 0 0 100%);\n  }\n  to {\n    clip-path: inset(0 0 0 0);\n  }\n}\n\n.FullLocationsList {\n  width: 100%;\n  max-width: 600px;\n  margin-left: 10%;\n  min-height: 40vh;\n}\n\n.FullLocationsTitle {\n  text-align: left;\n  margin-top: 5%;\n  margin-left: 5%;\n}\n\n.locations-side-menu {\n  height: fit-content;\n  width: 50%;\n  margin-top: 5%;\n  margin-bottom: 5%;\n}\n\n.locations-side-menu-mobile {\n  width: 100%;\n  margin-bottom: 5%;\n  margin-top: 5%;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.location-address-group {\n  display: flex;\n  justify-content: space-between;\n  margin-right: 10%;\n  margin-bottom: 4%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
