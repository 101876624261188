import React from "react";
import "./Modal.css";
import { Box } from "@mui/material";
import { withTheme } from "@mui/styles";

export const LoadingModal = (props) => {
  return (
    <Box
      className={`ei-loading-wrapper ${props.showOverlay ? "" : "no-overlay"}`}
      sx={{
        width: props.width || "100%",
        minWidth: props.minWidth,
        height: props.height || "100%",
        minHeight: props.minHeight,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center", // Perfectly centers loader & children
        alignItems: "center",
        position: props.showOverlay ? "fixed" : "relative",
        top: 0,
        left: 0,
      }}
    >
      {/* Centered Loader */}
      <Box
        className="ei-loader"
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gridGap: "4px",
          width: props.iconSize,
          height: props.iconSize,
          alignSelf: "center", // Ensures it centers properly inside flexbox
        }}
      >
        {[...Array(9)].map((_, i) => (
          <Box key={i} className="ei-loader-box" sx={{ backgroundColor: "currentColor" }} />
        ))}
      </Box>

      {/* Ensure children do not interfere with loader positioning */}
      {props.children && (
        <Box
          sx={{
            marginTop: "16px", // Space between loader & children
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="ei-loading-content"
        >
          {props.children}
        </Box>
      )}
    </Box>
  );
};

export default withTheme(LoadingModal);