import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#0bb195",
      light: "rgba(53, 201, 177, 0.135)",
      dark: "#095743de",
      contrastText: "#000",
    },
    secondary: {
      main: "#f5690bf5",
      light: "#ff9b7c",
      dark: "#b24708",
      contrastText: "#fff",
    },
    background: {
      default: "#225b5251",
      paper: "#ffffff",
      opaque: "#5857566c",
      shadedPrimary: "linear-gradient(135deg, rgba(94, 227, 201, 0.3) 0%, rgba(11, 177, 149, 0.5) 100%)",
      shadedSecondary: "linear-gradient(135deg, rgba(244, 151, 142, 0.3) 0%, rgba(198, 108, 92, 0.5) 100%)",
      complimentaryPrimary: "linear-gradient(180deg, hsla(0, 0%, 100%, 1) 0%, hsla(0, 0%, 49%, 1) 100%)",
      complimentarySecondary: "rgba(169, 169, 169, 0.3)",
    },
    banner: "linear-gradient(180deg, #ffffff 0%, #dcdcdc 100%)",
    divider: "rgba(0, 0, 0, 0.32)",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: "linear-gradient(180deg, #f1f8e9 10%, #696969 100%)",
          minHeight: "100vh",
          margin: 0,
          padding: 0,
          overflow: "hidden",
        },
        "*::-webkit-scrollbar": {
          width: "10px",
          height: "10px",
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: "#0bb195", // Matches primary color
          borderRadius: "8px",
          border: "2px solid #f9f9f2", // Background color of paper
        },
        "*::-webkit-scrollbar-track": {
          backgroundColor: "#e0e0e0",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
        },
      },
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#0bb195",
      light: "#5ee3c9",
      dark: "#3a8674",
      contrastText: "#fff",
    },
    secondary: {
      main: "#f5690bf5",
      light: "#ff9b7c",
      dark: "#b24708",
      contrastText: "#000",
    },
    background: {
      default: "linear-gradient(180deg, #505050e6 0%, #0e0c0cb1 90%)",   // Light background
      paper: "#121111",     // Dark paper color
      opaque: "#e4e4e4b7",  // Light, semi-transparent overlay
      shadedPrimary: "linear-gradient(135deg, rgba(94, 227, 201, 0.8) 0%, rgba(11, 177, 149, 0.5) 100%)",
      shadedSecondary: "linear-gradient(135deg, rgba(244, 151, 142, 0.3) 0%, rgba(198, 108, 92, 0.5) 100%)",
      complimentaryPrimary: "rgba(128, 128, 128, 0.3)",
      complimentarySecondary: "rgba(169, 169, 169, 0.4)",
    },
    banner: "linear-gradient(180deg, #2a2a2a 0%, #3e3e3e 100%)",
    divider: "rgba(255, 255, 255, 0.5)",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: "linear-gradient(180deg, #1a1a1a 0%, #2a2a2a 100%)",
          minHeight: "100vh",
          margin: 0,
          padding: 0,
          overflow: "hidden",
        },
        "*::-webkit-scrollbar": {
          width: "10px",
          height: "10px",
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: "#5ee3c9", // Lighter primary color
          borderRadius: "8px",
          border: "2px solid #2b2b2b", // Matches paper background color
        },
        "*::-webkit-scrollbar-track": {
          backgroundColor: "#1e1e1e",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.4)",
          overflow: "hidden",
        },
      },
    },
  },
});

export { lightTheme, darkTheme };