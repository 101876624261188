import moment from "moment-timezone";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import eiUpdateLocationSchedule from "../../../modules/api/ei/eiUpdateLocationSchedule";
import { EI_REQUEST_UPDATE_LOCATION_DATA, SEND_REQUEST } from "../../../sagas/actions";

class LocationSchedule extends Component {
  state = {
    errorMessage: null,
    schedule: {},
  };

  componentDidMount() {
    this.setState({
      schedule: { ...this.createPrevSchedule() },
    });
  }

  createPrevSchedule = () => {
    return {
      0: this.props.schedule[0],
      1: this.props.schedule[1],
      2: this.props.schedule[2],
      3: this.props.schedule[3],
      4: this.props.schedule[4],
      5: this.props.schedule[5],
      6: this.props.schedule[6],
    };
  };

  createNewScheduleObj = () => {
    const previousSchedule = this.createPrevSchedule();
    const newSchedule = {
      ...previousSchedule,
      ...this.state.schedule,
    };
    return Object.values(newSchedule);
  };

  updateLocalState = (i, data) => {
    this.setState({
      schedule: {
        ...this.state.schedule,
        [i]: data,
      },
    });
  };

  handleUpdate = (locationId) => {
    const newScheduleObj = this.createNewScheduleObj();

    for (let i = 0; i < newScheduleObj.length; i++) {
      const scheduleDay = newScheduleObj[i];
      if (
        !(
          moment(scheduleDay.open, "hh:mm a", true).isValid() ||
          moment(scheduleDay.open, "h:mm a", true).isValid() ||
          scheduleDay.open === "0:00 am"
        )
      ) {
        return this.setState({ errorMessage: "Incorrect OPEN format for day " + scheduleDay.day });
      }
      if (
        !(
          moment(scheduleDay.close, "hh:mm a", true).isValid() ||
          moment(scheduleDay.close, "h:mm a", true).isValid() ||
          scheduleDay.close === "0:00 am"
        )
      ) {
        return this.setState({ errorMessage: "Incorrect CLOSE format for day " + scheduleDay.day });
      }
    }

    this.setState({ errorMessage: null });
    const token = localStorage.getItem("token");
    this.props.requestUpdateLocationSchedule(this.props.siteId, locationId, token, newScheduleObj);
  };

  createDayRows = (scheduleData) => {
    return scheduleData.map((scheduleDay, i) => {
      return (
        <Grid container spacing={2} key={i} alignItems="center" sx={{ mb: 1 }}>
          <Grid item xs={12} sm={2}>
            <Typography fontWeight="bold">{scheduleDay.day}</Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              size="small"
              label="Open"
              value={this.state.schedule[`${i}`]?.open || ""}
              onChange={(e) => {
                const close = this.state.schedule[`${i}`]?.close || this.props.schedule[i].close;
                this.updateLocalState(i, {
                  day: scheduleDay.day,
                  open: e.target.value,
                  close: close,
                });
              }}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              size="small"
              label="Close"
              value={this.state.schedule[`${i}`]?.close || ""}
              onChange={(e) => {
                const open = this.state.schedule[`${i}`]?.open || this.props.schedule[i].open;
                this.updateLocalState(i, {
                  day: scheduleDay.day,
                  open: open,
                  close: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs="auto">
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={() => {
                this.updateLocalState(i, {
                  day: scheduleDay.day,
                  open: "0:00 am",
                  close: "0:00 am",
                });
              }}
            >
              CLOSED
            </Button>
          </Grid>
        </Grid>
      );
    });
  };

  render() {
    return (
      this.props.eiLocationData?.isSuccess && (
        <Box sx={{ p: 2, mb: 2 }}>
          <Typography variant="h5" fontWeight="bold" mb={2}>
            {this.props.locationName} Staffed Schedule
          </Typography>
          <Typography variant="body2" color="text.secondary" textAlign="left" mb={2}>
            This schedule determines when access credentials are automatically sent to clients. Credentials are only sent for appointments that fall outside of the defined staff hours.
          </Typography>
          <Typography variant="body2" mb={1}>
            12:00 pm is NOON | 12:00 am is MIDNIGHT
          </Typography>
          <Typography variant="body2" mb={6}>
            If the day is scheduled to be closed, please select the CLOSED button.
          </Typography>
          <Box>
            {this.createDayRows(this.props.schedule)}
            <Box ml={7} mt={3}>
              <Button
                sx={{ width: 200 }}
                variant="contained"
                onClick={() => this.handleUpdate(this.props.locationId)}
              >
                Update
              </Button>
            </Box>
            {this.state.errorMessage && (
              <Typography color="error" mt={2}>
                {this.state.errorMessage}
              </Typography>
            )}
          </Box>
        </Box>
      )
    )
  }
}

const mapStateToProps = (reduxState) => ({
  eiLocationData: reduxState.eiLocationData,
  schedule: reduxState.eiLocationData.schedule,
});

const mapDispatchToProps = (dispatch) => ({
  requestUpdateLocationSchedule: (siteId, locationId, token, schedule) => {
    dispatch({
      type: SEND_REQUEST,
      loadActionType: EI_REQUEST_UPDATE_LOCATION_DATA,
      apiCall: eiUpdateLocationSchedule,
      apiCallParams: {
        siteId,
        locationId,
        token,
        schedule,
      },
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationSchedule);
