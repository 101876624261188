// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-plot {
  width: 100%;
  margin-bottom: 2%;
}

.tool-tip-wrapper {
  color: white;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/EmployeeInterface/components/DataPlot.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".data-plot {\n  width: 100%;\n  margin-bottom: 2%;\n}\n\n.tool-tip-wrapper {\n  color: white;\n  font-weight: 700;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
