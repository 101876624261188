// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mark-check {
  color: green;
  font-size: 1.2em;
}

.mark-x {
  color: red;
  font-weight: bold;
  font-size: 2.1em;
}

.ei-commands-title {
  font-size: x-large;
  font-weight: 800;
}

.ei-room-commands-header {
    margin-bottom: 50px;
}

.ei-room-commands-wrapper {
    display: flex;
    padding-bottom: 5%;
}

.ei-room-command-row {
  display: flex;
  /* align-content: center; */
  justify-content: space-between;
  text-align: left;
  margin-bottom: 7%;
  border: 1px solid rgba(17, 17, 17, 0.145);
  background-color: rgba(17, 17, 17, 0.145);
  border-radius: 5px;
  padding: 2%;
  /* margin-right: 5%; */
}

.ei-room-commands-group {
  background-color: grey;
  margin-right: 5%;
  padding: 2%;
  border: 1px solid black;
  border-radius: 5px;
}

.ei-room-response-status-group {
  display: flex;
  align-content: center;
}

.ei-room-response-status-wrapper {
  align-content: center;
  display: flex;
}

.ei-room-command-send {
    max-width: 150px;
    max-height: 50PX;
    margin-left: 50px;
}

.ei-room-response-group {
    padding-top: 5%;
}

/* WEB  */

/* MOBILE  */
`, "",{"version":3,"sources":["webpack://./src/components/EmployeeInterface/EmployeeInterfaceRoomView/roomViewCommands.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,8BAA8B;EAC9B,gBAAgB;EAChB,iBAAiB;EACjB,yCAAyC;EACzC,yCAAyC;EACzC,kBAAkB;EAClB,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,WAAW;EACX,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,aAAa;AACf;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA,SAAS;;AAET,YAAY","sourcesContent":[".mark-check {\n  color: green;\n  font-size: 1.2em;\n}\n\n.mark-x {\n  color: red;\n  font-weight: bold;\n  font-size: 2.1em;\n}\n\n.ei-commands-title {\n  font-size: x-large;\n  font-weight: 800;\n}\n\n.ei-room-commands-header {\n    margin-bottom: 50px;\n}\n\n.ei-room-commands-wrapper {\n    display: flex;\n    padding-bottom: 5%;\n}\n\n.ei-room-command-row {\n  display: flex;\n  /* align-content: center; */\n  justify-content: space-between;\n  text-align: left;\n  margin-bottom: 7%;\n  border: 1px solid rgba(17, 17, 17, 0.145);\n  background-color: rgba(17, 17, 17, 0.145);\n  border-radius: 5px;\n  padding: 2%;\n  /* margin-right: 5%; */\n}\n\n.ei-room-commands-group {\n  background-color: grey;\n  margin-right: 5%;\n  padding: 2%;\n  border: 1px solid black;\n  border-radius: 5px;\n}\n\n.ei-room-response-status-group {\n  display: flex;\n  align-content: center;\n}\n\n.ei-room-response-status-wrapper {\n  align-content: center;\n  display: flex;\n}\n\n.ei-room-command-send {\n    max-width: 150px;\n    max-height: 50PX;\n    margin-left: 50px;\n}\n\n.ei-room-response-group {\n    padding-top: 5%;\n}\n\n/* WEB  */\n\n/* MOBILE  */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
