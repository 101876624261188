// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 300) {
    .sw-header-title {
        font-size: 4rem;
        z-index: 1!important;
    }
}


@media (max-width: 500) {
    .sw-header-title {
        z-index: 1!important;
        font-size: 6rem;
    }
}

@media (max-width: 1000) {
    .sw-header-title {
        z-index: 1!important;
        font-size: 10rem;
    }
}


@media (max-width: 1200) {
    .sw-header-title {
        z-index: 1!important;
        font-size: 12rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/App/SweatworksStyles.css"],"names":[],"mappings":"AAAA;IACI;QACI,eAAe;QACf,oBAAoB;IACxB;AACJ;;;AAGA;IACI;QACI,oBAAoB;QACpB,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,oBAAoB;QACpB,gBAAgB;IACpB;AACJ;;;AAGA;IACI;QACI,oBAAoB;QACpB,gBAAgB;IACpB;AACJ","sourcesContent":["@media (max-width: 300) {\n    .sw-header-title {\n        font-size: 4rem;\n        z-index: 1!important;\n    }\n}\n\n\n@media (max-width: 500) {\n    .sw-header-title {\n        z-index: 1!important;\n        font-size: 6rem;\n    }\n}\n\n@media (max-width: 1000) {\n    .sw-header-title {\n        z-index: 1!important;\n        font-size: 10rem;\n    }\n}\n\n\n@media (max-width: 1200) {\n    .sw-header-title {\n        z-index: 1!important;\n        font-size: 12rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
