import {
  EI_SAUNA_DATA,
  EI_UPDATE_SAUNA_DATA_AUTOMATIONS,
} from "../../sagas/actions";
import { makeLoadingActionTypes } from "../../sagas/loadingSaga";

const { loading, success, failure, reset } =
  makeLoadingActionTypes(EI_SAUNA_DATA);
const defaultState = {
  payload: null,
  isLoading: false,
  error: null,
  automations: {
    sauna: false,
    redLight: false,
  },
};
const eiSaunadata = (state = defaultState, action) => {
  switch (action.type) {
    case loading:
      return { ...state, isLoading: true, error: null };
      case success:
      console.log("called EI_SAUNA_DATA! payload: ", action.payload);
      return {
        ...state,
        payload: action.payload,
        isLoading: false,
        error: null,
      };
    case failure:
      return { isLoading: false, error: action.error };
    case reset:
      return { defaultState, isLoading: false, error: null };
    case EI_UPDATE_SAUNA_DATA_AUTOMATIONS:
      return {
        ...state,
        payload: {
          ...state.payload,
          data: {
            ...state.payload.data,
            automations: {
              sauna: action.payload.sauna,
              redLight: action.payload.redLight,
            },
          }
        },
        isLoading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default eiSaunadata;
