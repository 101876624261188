import axios from "axios";
import { SWEATWORKS_API } from "../../../TestingVariables";

function* requestEISaunaData(apiCallParams) {
  const token = apiCallParams.token;
  const headers = {
    "Content-Type": "application/json",
    siteId: apiCallParams.siteId,
    Authorization: `Bearer ${token}`,
    DataDate: apiCallParams.date,
    DayQuarter: apiCallParams.dayQuarter,
  };
  console.log('requestEISaunaData headers: ', headers);
  return yield axios
    .get(`${SWEATWORKS_API}/ei/data/room/${apiCallParams.staffId}`, {
      headers: headers,
    })
    .then(async(res) => {
      console.log("requestEISaunaData res.data: ", res.data);
      return res.data;
    }).catch((err)=>{
      console.log('requestEISaunaData error: ', err);
    })
}

export default requestEISaunaData;