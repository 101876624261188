import React, { useState } from "react";
import { connect } from "react-redux";
import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import requestEIAuthToken from "../../../modules/api/ei/requestEIAuthToken";
import { SEND_REQUEST, EI_LOGIN } from "../../../sagas/actions";
import Roller from "../../General/animations/Roller";

function EILoginView(props) {
  const [loginUsername, setLoginUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  return (
    <Paper
      elevation={6}
      sx={{
        padding: 4,
        width: 400,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        alignItems: "center",
      }}
    >
      <Typography variant="h5" gutterBottom>
        STAFF LOGIN
      </Typography>

      <TextField
        label="Username"
        variant="outlined"
        fullWidth
        value={loginUsername}
        onChange={(e) => setLoginUsername(e.target.value)}
        autoComplete="off"
        name="username"
      />

      <TextField
        label="Password"
        type="password"
        variant="outlined"
        fullWidth
        value={loginPassword}
        onChange={(e) => setLoginPassword(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            props.requestLoginToken(
              loginUsername,
              loginPassword,
              props.refreshTokenStatus
            );
          }
        }}
        autoComplete="new-password"
        name="password"
      />

      {props.reduxState.loginMessageBox.message && (
        <Box display="flex" alignItems="center" gap={1} mt={1}>
          <Roller />
          <Typography variant="body2" color="error">
            {props.reduxState.loginMessageBox.message}
          </Typography>
        </Box>
      )}

      <Box sx={{ display: "flex", gap: 2, width: "100%", marginTop: 2 }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            props.requestLoginToken(
              loginUsername,
              loginPassword,
              props.refreshTokenStatus
            );
          }}
        >
          SUBMIT
        </Button>
        <Button
          variant="outlined"
          fullWidth
          onClick={() => {
            props.set("loginView", "signUp");
          }}
        >
          SIGN UP
        </Button>
      </Box>
    </Paper>
  );
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

const mapDispatchToProps = (dispatch) => ({
  requestLoginToken: async (loginUsername, loginPassword, callback) => {
    dispatch({
      type: SEND_REQUEST,
      loadActionType: EI_LOGIN,
      apiCall: requestEIAuthToken,
      apiCallParams: {
        username: loginUsername,
        password: loginPassword,
        callBack: callback,
      },
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EILoginView);
