import React from "react";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  useTheme,
  Divider,
} from "@mui/material";
import DropDownMenu from "../components/DropDownMenu";
import DataPlot from "../components/DataPlot";
import requestEISaunaData from "../../../modules/api/ei/requestEISaunaData";
import { EI_SAUNA_DATA, SEND_REQUEST } from "../../../sagas/actions";

function RoomViewData(props) {
  const theme = useTheme();

  const handleClick = () => {
    props.requestUpdateSaunaData(
      props.siteId,
      localStorage.getItem("token"),
      props.staffId,
      props.dataDate,
      props.dayQuarter
    );
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 2,
      }}
    >
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        DATA: {props.roomName}
      </Typography>
      <Divider sx={{ mb: 1, pt: 1 }} />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "stretch",
          gap: 3,
          mb: 3,
          [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "stretch",
          },
        }}
      >
        <Box sx={{ minWidth: 200, flex: 1 }}>
          <Typography variant="subtitle2" color="text.primary" mb={0.5}>
            DATE
          </Typography>
          <TextField
            type="date"
            value={props.dataDate}
            onChange={(e) => props.set("dataDate", e.target.value)}
            size="small"
            fullWidth
          />
        </Box>
        <Box sx={{ minWidth: 200, flex: 1 }}>
          <Typography variant="subtitle2" color="text.primary" mb={0.5}>
            TIME FRAME
          </Typography>
          <DropDownMenu set={props.set} dayQuarter={props.dayQuarter} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            minWidth: 120,
            flex: 1,
          }}
        >
          <Button
            variant="contained"
            color="primary" // ✅ Uses your theme's primary color
            onClick={handleClick}
            sx={{ height: 40 }}
          >
            FETCH NEW DATA
          </Button>
        </Box>
      </Box>
      <Divider sx={{ mb: 2 }} />
      <Box>
        {(props.version === 1 || props.version === 2) && (
          <Box>
            {(props.version === 1 ? [ // Version 1
              ["temp", 300, "#ffffff", "Sauna Temperature °F", { min: 70, max: 160 }, "colorBlue"],
              ["preheatMode", 100, "#ffffff", "Preheat Mode Active", { min: 0, max: 1 }, "colorGreen"],
              ["heaterRelay", 100, "#ffffff", "Sauna Heaters", { min: 0, max: 1 }, "colorYellow"],
              ["saunaPower", 100, "#ffffff", "Sauna Power (Panel is on)", { min: 0, max: 1 }, "colorWhite"], // Version 2
              ["activeRedLight", 100, "#ffffff", "Red Light Engaged", { min: 0, max: 1 }, "colorRed"],
              ["online", 100, "#ffffff", "Sauna Online Status", { min: 0, max: 1 }, "colorBlack"],
            ] : [
              ["power_on", 80, "#00cc66", "Power On (Display is on)", { min: 0, max: 1 }, "colorGreen"],
              ["actual_temperature_f", 250, "#ff4d4d", "Actual Sauna Temperature °F", { min: 70, max: 150 }, "colorRed"],
              ["set_temperature_f", 200, "#ffa500", "Target Temperature °F (Displayed on the panel)", { min: 110, max: 150 }, "colorOrange"],
              ["time_remaining_minutes", 100, "#565656", "Time Remaining (min)", { min: 0, max: 10 }, "colorWhite"],
              ["outside_light_on", 100, "#3399ff", "Outside Light On", { min: 0, max: 1 }, "colorBlue"],
              ["inside_light_on", 100, "#b266ff", "Inside Light On", { min: 0, max: 1 }, "colorPurple"],
              ["aux_on", 100, "#33cccc", "AUX On", { min: 0, max: 1 }, "colorTeal"],
              ["power_level_percent", 100, "#ffff66", "Power Level %", { min: 0, max: 100 }, "colorYellow"],
              ["is_celsius", 100, "#cccccc", "Set to Celsius°", { min: 0, max: 1 }, "colorGray"],
              ["reservation_minutes", 100, "#ff66b2", "Reservation Length (min)", { min: 0, max: 10 }, "colorPink"],
            ]).map(([key, height, color, name, domain, fill]) => (
              <DataPlot
                key={key}
                data={props.plotData}
                dataKey={key}
                height={height}
                color={color}
                name={name}
                domain={domain}
                fill={fill}
                tick
              />
            ))}
          </Box>
        )}
      </Box>
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  version: state.eiSaunaData?.payload?.version,
});

const mapDispatchToProps = (dispatch) => ({
  requestUpdateSaunaData: (siteId, token, staffId, date, dayQuarter) => {
    dispatch({
      type: SEND_REQUEST,
      loadActionType: EI_SAUNA_DATA,
      apiCall: requestEISaunaData,
      apiCallParams: { siteId, token, staffId, date, dayQuarter },
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RoomViewData);
