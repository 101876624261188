// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pricing {
  height: 100%;
}



.MembershipsIntroText {
  padding-top: 75px;
  color: black;
}

.MembershipsIntroTextSubtext {
  font-size: medium;
}

.Title {
  margin-top: 1%;
}

@media screen and(max-width: 1800px) {
  .MembershipsIntroText {
    font-size: xx-large;
  }
}

@media screen and(max-width: 1200px) {
  .MembershipsIntroText {
    font-size: x-large;
  }
}
@media screen and(max-width: 900px) {
  .MembershipsIntroText {
    font-size: larger;
  }
}
@media screen and(max-width: 600px) {
  .MembershipsIntroText {
    font-size: large;
  }
}
@media screen and(max-width: 400px) {
  .MembershipsIntroText {
    font-size: large;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Pricing/Memberships.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;;;AAIA;EACE,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;AACA;EACE;IACE,iBAAiB;EACnB;AACF;AACA;EACE;IACE,gBAAgB;EAClB;AACF;AACA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".Pricing {\n  height: 100%;\n}\n\n\n\n.MembershipsIntroText {\n  padding-top: 75px;\n  color: black;\n}\n\n.MembershipsIntroTextSubtext {\n  font-size: medium;\n}\n\n.Title {\n  margin-top: 1%;\n}\n\n@media screen and(max-width: 1800px) {\n  .MembershipsIntroText {\n    font-size: xx-large;\n  }\n}\n\n@media screen and(max-width: 1200px) {\n  .MembershipsIntroText {\n    font-size: x-large;\n  }\n}\n@media screen and(max-width: 900px) {\n  .MembershipsIntroText {\n    font-size: larger;\n  }\n}\n@media screen and(max-width: 600px) {\n  .MembershipsIntroText {\n    font-size: large;\n  }\n}\n@media screen and(max-width: 400px) {\n  .MembershipsIntroText {\n    font-size: large;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
