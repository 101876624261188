import React, { Component } from "react";
import "./EmployeeInterface.css";
import { connect } from "react-redux";
import EmployeeInterfaceLogin from "./EmployeeLogin/EmployeeInterfaceLogin";
import moment from "moment-timezone";
import EmployeeInterfaceHome from "./EmployeeInterfaceHome/EmployeeInterfaceHome";
import EmployeeInterfaceRoomView from "./EmployeeInterfaceRoomView/EmployeeInterfaceRoomView";
import requestEISaunaData from "../../modules/api/ei/requestEISaunaData";
import requestEILocationData from "../../modules/api/ei/requestEILocationData";
import { EI_LOCATION_DATA, EI_SAUNA_DATA, SEND_REQUEST } from "../../sagas/actions";
import EmployeeInterfaceLocationView from "./EmployeeInterfaceLocationView/EmployeeInterfaceLocationView";
// import { LoadingWrapper } from "../General/LoadingWrapper";
const { currentQuarterDay } = require("../../modules/tools");

class EmployeeInterface extends Component {
  state = {
    tokenValid: false,
    dataDate: moment().format("YYYY-MM-DD"),
    dayQuarter: null,
    view: "home",
    loginView: "login",
    siteId: null,
    staffId: null,
    roomName: null,
    saunaType: null,
    locationId: null,
    locationName: null,
  };

  componentDidMount() {
    this.refreshTokenStatus();
    this.set("dayQuarter", currentQuarterDay());
  }

  set = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  refreshLocationData = (siteId, locationId, locationName) => {
    const token = localStorage.getItem("token");
    this.props.requestLocationData(siteId, locationId, token);
    this.setState({
      siteId: siteId,
      locationId: locationId,
      locationName: locationName,
    });
  };

  refreshSaunaData = (siteId, staffId, name, saunaType) => {
    const token = localStorage.getItem("token");
    this.props.requestRoomData(siteId, staffId, token, this.state.dataDate, this.state.dayQuarter);
    this.setState({
      siteId: siteId,
      staffId: staffId,
      roomName: name,
      saunaType: saunaType,
    });
  };

  sleep = (s) => {
    const ms = s * 1000;
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  refreshTokenStatus = () => {
    this.sleep(30);
    const expires = localStorage.getItem("expires");
    const now = moment().format();
    if (moment(expires).isValid()) {
      if (moment(now).isBefore(expires)) {
        this.set("tokenValid", true);
      } else {
        this.set("tokenValid", false);
      }
    } else {
      this.set("tokenValid", false);
    }
  };

  renderViews = (view, siteId, staffId, roomName, saunaType) => {
    if (view === "room") {
      return (
        <EmployeeInterfaceRoomView
          siteId={siteId}
          staffId={staffId}
          roomName={roomName}
          saunaType={saunaType}
          set={this.set}
          dataDate={this.state.dataDate}
          dayQuarter={this.state.dayQuarter}
          setDataDate={this.setDataDate}
          refreshTokenStatus={this.refreshTokenStatus}
        />
      );
    } else if (view === "home") {
      return (
        <EmployeeInterfaceHome
          tokenValid={this.state.tokenValid}
          set={this.set}
          refreshSaunaData={this.refreshSaunaData}
          refreshTokenStatus={this.refreshTokenStatus}
          refreshLocationData={this.refreshLocationData}
        />
      );
    } else if (view === "location") {
      return (
        <EmployeeInterfaceLocationView
          tokenValid={this.state.tokenValid}
          set={this.set}
          refreshTokenStatus={this.refreshTokenStatus}
          siteId={siteId}
          locationId={this.state.locationId}
          locationName={this.state.locationName}
          refreshLocationData={this.refreshLocationData}
        />
      );
    }
  };

  render() {
    return (
      <div className="ei-container">
        <div className="bgColorBlack">
          <div className="ei-content">
            {!this.state.tokenValid ? (
              <EmployeeInterfaceLogin
                loginView={this.state.loginView}
                set={this.set}
                refreshTokenStatus={this.refreshTokenStatus}
              />
            ) : (
              this.renderViews(
                this.state.view,
                this.state.siteId,
                this.state.staffId,
                this.state.roomName,
                this.state.saunaType
              )
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestRoomData: async (siteId, id, token, date, dayQuarter) => {
      dispatch({
        type: SEND_REQUEST,
        loadActionType: EI_SAUNA_DATA,
        apiCall: requestEISaunaData,
        apiCallParams: {
          siteId: siteId,
          token: token,
          staffId: id,
          date: date,
          dayQuarter: dayQuarter,
        },
      });
    },
    requestLocationData: async (siteId, locationId, token) => {
      dispatch({
        type: SEND_REQUEST,
        loadActionType: EI_LOCATION_DATA,
        apiCall: requestEILocationData,
        apiCallParams: {
          siteId: siteId,
          token: token,
          locationId: locationId,
        },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeInterface);
