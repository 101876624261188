import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Button,
  Paper,
  TextField,
  Typography
} from "@mui/material";
import { SEND_REQUEST, EI_SIGNUP } from "../../../sagas/actions";
import requestEISignup from "../../../modules/api/ei/requestEISignup";
import Roller from "../../General/animations/Roller";

function EISignUpView(props) {
  const [loginUsername, setLoginUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loginPasswordConfirm, setLoginPasswordConfirm] = useState("");
  const [invite, setInvite] = useState("");
  const [message, setMessage] = useState(
    "Password must be more than 8 characters, at least 1 uppercase, 1 lowercase, and 1 number."
  );

  const validateData = (password, cPassword, username, invite) => {
    if (password !== cPassword) {
      setMessage("Passwords Do Not Match!");
      return false;
    }

    const lower = /(?=.*[a-z])/.test(password);
    const upper = /(?=.*[A-Z])/.test(password);
    const number = /(?=.*[0-9])/.test(password);
    const length = password.length >= 8;
    const lengthUsername = username.length >= 4;

    if (!lengthUsername) {
      setMessage("Username must be at least 4 characters.");
      return false;
    }
    if (!length) {
      setMessage("Password too short! Must be at least 8 characters.");
      return false;
    }
    if (!number) {
      setMessage("Password must contain a number.");
      return false;
    }
    if (!lower) {
      setMessage("Password must have a lowercase letter.");
      return false;
    }
    if (!upper) {
      setMessage("Password must have an uppercase letter.");
      return false;
    }
    if (!invite) {
      setMessage("No Invite Code Entered!");
      return false;
    }

    setMessage("");
    return true;
  };

  return (
    <Paper
      elevation={6}
      sx={{
        p: 4,
        width: "100%",
        maxWidth: 420,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Typography variant="h5" gutterBottom>
        EMPLOYEE SIGNUP
      </Typography>

      <TextField
        label="Username"
        value={loginUsername}
        onChange={(e) => setLoginUsername(e.target.value)}
        fullWidth
        autoComplete="off"
      />
      <TextField
        label="Password"
        type="password"
        value={loginPassword}
        onChange={(e) => setLoginPassword(e.target.value)}
        fullWidth
        autoComplete="new-password"
      />
      <TextField
        label="Confirm Password"
        type="password"
        value={loginPasswordConfirm}
        onChange={(e) => setLoginPasswordConfirm(e.target.value)}
        fullWidth
        autoComplete="new-password"
      />
      <TextField
        label="Invite Code"
        type="password"
        value={invite}
        onChange={(e) => setInvite(e.target.value)}
        fullWidth
        autoComplete="new-password"
      />

      {message && (
        <Typography variant="body2" color="text.secondary" fontStyle="italic">
          {message}
        </Typography>
      )}

      {props.message && (
        <Box display="flex" alignItems="center" gap={1} mt={1}>
          <Roller />
          <Typography variant="body2" color="error">
            {props.message}
          </Typography>
        </Box>
      )}

      <Box display="flex" gap={2} width="100%" mt={2}>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => props.set("loginView", "login")}
        >
          SIGN IN
        </Button>
        <Button
          fullWidth
          variant="contained"
          onClick={(e) => {
            e.preventDefault();
            const isValid = validateData(
              loginPassword,
              loginPasswordConfirm,
              loginUsername,
              invite
            );
            if (isValid) {
              props.requestSignup(loginUsername, loginPassword, invite);
            }
          }}
        >
          SUBMIT
        </Button>
      </Box>
    </Paper>
  );
}

const mapStateToProps = (reduxState) => ({
  message: reduxState?.signupMessageBox?.message,
});

const mapDispatchToProps = (dispatch) => ({
  requestSignup: (loginUsername, loginPassword, invite) => {
    dispatch({
      type: SEND_REQUEST,
      loadActionType: EI_SIGNUP,
      apiCall: requestEISignup,
      apiCallParams: {
        username: loginUsername,
        password: loginPassword,
        invite: invite,
      },
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EISignUpView);
