import React from "react";
import "./DataPlot.css";
import {
  AreaChart ,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

function DataPlot(props) {
  return (
    <div className="data-plot" style={{ height: props.height }}>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={900}
          height={props.height}
          data={props.data}
        >
          <defs>
            <linearGradient id="colorGreen" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#0dc609" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#0dc609" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorBlue" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#62ddd0" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#62ddd0" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorYellow" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#d6df20" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#d6df20" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorRed" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#e20e0e" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#e20e0e" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorWhite" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#d5dddd" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#d5dddd" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorBlack" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#1a1a1a" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#1a1a1a" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="dateTime" />
          <YAxis
            yAxisId="left"
            tick={props.tick}
            domain={[props.domain.min, props.domain.max]}
          />
          <Tooltip contentStyle={{color: 'white', backgroundColor: 'black'}} labelClassName="tool-tip-wrapper" offset={-250} />
          <Legend />
          <Area
            name={props.name}
            type="monotone"
            dataKey={props.dataKey}
            yAxisId="left"
            stroke={props.color}
            fillOpacity={1}
            fill={`url(#${props.fill})`}
            // activeDot={{ r: 0 }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default DataPlot;
