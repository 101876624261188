// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  margin: 0%;
  padding: 0%;
  height: 100%;
  width: 100%;
  transform: scale(1);
  /* overflow-x: hidden; */
}


button:focus,
button:active {
  outline: none !important; /* Use !important to override any inline styles or other CSS rules */
  border: none; /* Optionally remove any border */
  box-shadow: none; /* Optionally remove any box-shadow */
}`, "",{"version":3,"sources":["webpack://./src/components/App/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,wBAAwB;AAC1B;;;AAGA;;EAEE,wBAAwB,EAAE,oEAAoE;EAC9F,YAAY,EAAE,iCAAiC;EAC/C,gBAAgB,EAAE,qCAAqC;AACzD","sourcesContent":[".App {\n  text-align: center;\n  margin: 0%;\n  padding: 0%;\n  height: 100%;\n  width: 100%;\n  transform: scale(1);\n  /* overflow-x: hidden; */\n}\n\n\nbutton:focus,\nbutton:active {\n  outline: none !important; /* Use !important to override any inline styles or other CSS rules */\n  border: none; /* Optionally remove any border */\n  box-shadow: none; /* Optionally remove any box-shadow */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
