import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import FailureModal from "./FailureModal";
import LoadingModal from "./LoadingModal";

export const LoadingWrapper = ({
  children,
  isLoading,
  isSuccess,
  isFailure,
  showOverlay,
  showChildrenOnLoading,
  showCheckmarkOnSuccess = true,
  minHeight,
  minWidth,
  height,
  width,
  iconSize,
}) => {
  const [showCheckmark, setShowCheckmark] = useState(false);
  const [hasRequestStarted, setHasRequestStarted] = useState(false);

  // Detect the start of the request lifecycle
  useEffect(() => {
    if (isLoading || isFailure) {
      setHasRequestStarted(true); // Mark that the lifecycle has started
    }
  }, [isLoading, isFailure]);

  // Trigger the checkmark animation only when a request has successfully completed after starting
  useEffect(() => {
    if (hasRequestStarted && isSuccess) {
      setShowCheckmark(true);
      const timeout = setTimeout(() => setShowCheckmark(false), 2000); // Show checkmark for 2 seconds
      return () => clearTimeout(timeout);
    }
  }, [isSuccess, hasRequestStarted]);

  // Handle different states
  if (isLoading) {
    console.log("LoadingWrapper isLoading: ", isLoading);
    return (
      <LoadingModal
        showOverlay={showOverlay}
        iconSize={iconSize}
        height={height}
        width={width}
        minWidth={minWidth}
        minHeight={minHeight ? minHeight : "50%"}
      >
        {!showChildrenOnLoading ? null : children}
      </LoadingModal>
    );
  }

  if (isFailure) {
    return <FailureModal>{children}</FailureModal>;
  }

  return (
    <div className="loading-wrapper-container">
      {showCheckmarkOnSuccess && showCheckmark && <div className="success-checkmark" />}
      {children}
    </div>
  );
};

const mapStateToProps = (reduxState) => {
  const { isLoading, error } = reduxState.eiSaunaData || {};

  const isSuccess = !isLoading && !error;
  const isFailure = !!error;

  // Only enable success or failure states once a request cycle has started
  const isRequestActive = isLoading || isFailure;

  return {
    isLoading,
    isSuccess: isRequestActive && isSuccess, // Prevent premature success state
    isFailure,
  };
};

export default connect(mapStateToProps)(LoadingWrapper);