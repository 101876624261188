// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pricing-group {
  height: 1400px;
}

.PricingWrapper {
  min-height: 100vh;
  background: rgba(26, 212, 175, 0.904);
  background: linear-gradient(171deg, rgba(125, 236, 214, 0.904) 0%, #ff771c61 100%);
  background: -moz-linear-gradient(
    171deg,
    rgba(26, 212, 175, 0.904) 0%,
    #ff751c 100%
  );
}

.PricingContent {
  background: -webkit-linear-gradient(
    rgba(50, 221, 181, 0.231) 100%,
    rgba(0, 0, 0, 0.7) 90%,
    rgba(232, 115, 25, 0.2) 50%,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.2) 95%,
    rgba(0, 0, 0, 0) 100%
  );
  min-height: 100vh;
  /* background: -webkit-linear-gradient(
      rgba(122, 122, 122, 0.13) 0%,
      rgba(169, 169, 169, 0.9) 50%,
      rgba(49, 177, 154, 0) 100%
      ); */
  /* background-color: #000000; */
  color: black;
}

.CardTitle {
  justify-content: flex-start;
  margin-left: 10%;
}

.CardMoneyGroup {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: black;
}

.CardMoneySign {
  font-size: medium;
  align-self: flex-start;
}

.CardMoneyValue {
  font-size: xx-large;
}

.CardMoneyText {
  font-size: small;
}
`, "",{"version":3,"sources":["webpack://./src/components/Pricing/Pricing.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,qCAAqC;EACrC,kFAAkF;EAMlF;;;;GAIC;AACH;;AAEA;EACE;;;;;;;GAOC;EACD,iBAAiB;EACjB;;;;UAIQ;EACR,+BAA+B;EAC/B,YAAY;AACd;;AAEA;EACE,2BAA2B;EAC3B,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".pricing-group {\n  height: 1400px;\n}\n\n.PricingWrapper {\n  min-height: 100vh;\n  background: rgba(26, 212, 175, 0.904);\n  background: linear-gradient(171deg, rgba(125, 236, 214, 0.904) 0%, #ff771c61 100%);\n  background: -webkit-linear-gradient(\n    171deg,\n    rgba(26, 212, 175, 0.904) 0%,\n    #ff751c 100%\n  );\n  background: -moz-linear-gradient(\n    171deg,\n    rgba(26, 212, 175, 0.904) 0%,\n    #ff751c 100%\n  );\n}\n\n.PricingContent {\n  background: -webkit-linear-gradient(\n    rgba(50, 221, 181, 0.231) 100%,\n    rgba(0, 0, 0, 0.7) 90%,\n    rgba(232, 115, 25, 0.2) 50%,\n    rgba(0, 0, 0, 0.5) 0%,\n    rgba(0, 0, 0, 0.2) 95%,\n    rgba(0, 0, 0, 0) 100%\n  );\n  min-height: 100vh;\n  /* background: -webkit-linear-gradient(\n      rgba(122, 122, 122, 0.13) 0%,\n      rgba(169, 169, 169, 0.9) 50%,\n      rgba(49, 177, 154, 0) 100%\n      ); */\n  /* background-color: #000000; */\n  color: black;\n}\n\n.CardTitle {\n  justify-content: flex-start;\n  margin-left: 10%;\n}\n\n.CardMoneyGroup {\n  display: flex;\n  align-items: flex-end;\n  justify-content: center;\n  color: black;\n}\n\n.CardMoneySign {\n  font-size: medium;\n  align-self: flex-start;\n}\n\n.CardMoneyValue {\n  font-size: xx-large;\n}\n\n.CardMoneyText {\n  font-size: small;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
