// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* WEB  */

/* MOBILE  */
`, "",{"version":3,"sources":["webpack://./src/components/EmployeeInterface/EmployeeInterfaceRoomView/EmployeeInterfaceRoomView.css"],"names":[],"mappings":";AACA,SAAS;;AAET,YAAY","sourcesContent":["\n/* WEB  */\n\n/* MOBILE  */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
