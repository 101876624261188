import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Not needed if you're using MDB carousel
import Star from "./Star";
import { ReviewsContent } from "./ReviewsContent";
import "./ReviewsGroup.css";
import "./sw-carousel.css";
import orangeBg from "../../images/orange_bg.png";
import "../App/SweatworksStyles.css";
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit'; // ✅ FIXED

export default class Reviews extends Component {
  convertToStars = (numberOfStars) => {
    return [...Array(numberOfStars)].map((e, i) => <Star key={i} />);
  };
  createReviews = () => {
    return ReviewsContent.map((review, i) => {
      return (
        <MDBCarouselItem
          className={i === 0 ? "carousel-item active" : "carousel-item"}
          key={i}
        >
          <div className="carousel-item-wrapper">
            <div className="ReviewBGOuter">
              <div className="ReviewBGInner">
                <div className="ReviewHeaderGroup">
                  <div className="ReviewHeaderText">
                    <div className="ReviewName ColorWhite TitleFont">
                      {review.name}
                    </div>
                    <div className="ReviewDate ItalicContentFont ColorWhite">
                      {review.date}
                    </div>
                  </div>
                  <div className="ReviewStarsWrapper">
                    {this.convertToStars(review.stars)}
                  </div>
                </div>
                <div className="ReviewContent ColorWhite CScroll">
                  "{review.content}"
                </div>
              </div>
            </div>
          </div>
        </MDBCarouselItem>
      );
    });
  };

  render() {
    return (
      <div className="Reviews" style={{ backgroundImage: `url(${orangeBg})` }}>
        <div className="ReviewsGroup">
          <MDBCarousel
            interval={55000}
            showIndicators
            showControls
            fade
          >
            <div className="what-our-clients-are-saying TitleFont ColorWhite sw-header-title">
              WHAT OUR CLIENTS ARE SAYING.
            </div>

            {this.createReviews()}
          </MDBCarousel>
        </div>
      </div>
    );
  }
}