import axios from "axios";
import { put } from "redux-saga/effects";
import { EI_RESET_LOCATION_DATA } from "../../../sagas/actions";
import { SWEATWORKS_API } from "../../../TestingVariables";

function* eiAddLocationScheduleException(apiCallParams) {
  yield put({ type: EI_RESET_LOCATION_DATA });
  const { token, siteId, locationId, date, open, close } = apiCallParams;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  console.log("eiAddLocationScheduleException request data!: ", siteId, token, date, open, close);
  const body = {
    siteId: siteId,
    date: date,
    open: open,
    close: close,
  };

  return yield axios
    .post(`${SWEATWORKS_API}/ei/schedule/add_exception/${locationId}`, body, { headers: headers })
    .then((res) => {
      console.log("eiAddLocationScheduleException res.status: ", res.status);
      console.log("res.data: ", res?.data);
    });
}

export default eiAddLocationScheduleException;
