import "./App.css";
import React from 'react';
import { Route, Routes } from "react-router-dom";
import AccessContentManager from "../Access/AccessContentManager";
import ExperiencePage from "../Experience/ExperiencePage";
import Pricing from "../Pricing/Pricing";
import "./fonts.css";
import './Themes.css'
import Home from "../Home/Home";
import LocationsPage from "../Map/LocationsPage";
import BenefitImagesPage from "../Benefits/BenefitImagesPage";
import ProductsPage from "../Products/ProductsPage";
import ServicesPage from "../Services/ServicesPage";
import "./Styles.css";
import RedLightPage from "../RedLight/RedLightPage";
import BlogPage from "../Blog/BlogPage";
import BookPage from "../Book/BookPage";
import EmployeeInterface from "../EmployeeInterface/EmployeeInterface";
// import HomeUnderConstruction from "./HomeUnderConstruction";
import BlogPageHome from "../Blog/BlogPageHome";
import { ThemeProvider } from "@mui/material";
import { darkTheme, lightTheme } from "../../themes/theme";

function App() {
  // const  [isDarkMode, setIsDarkMode] = React.useState(false);
  const getTheme = () => {
    if (false) {
    // if (isDarkMode) {
      return darkTheme;
    } else {
      return lightTheme;
    }
  }
  return (
    <div className="App">

      <ThemeProvider theme={getTheme()}>
        <Routes>
          <Route path="/a/:key" element={<AccessContentManager />} />
          <Route path="/onyx" element={<RedLightPage />} />
          <Route path="/experience" element={<ExperiencePage />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/locations" element={<LocationsPage />} />
          <Route path="/locations/:id" element={<LocationsPage />} />
          <Route path="/book" element={<BookPage />} />
          <Route path="/blog/blog_page/:blog" element={<BlogPage />} />
          <Route path="/blog" element={<BlogPageHome />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/shop" element={<Pricing />} />
          <Route path="/benefits/:benefit" element={<BenefitImagesPage />} />
          <Route path="/benefits" element={<BenefitImagesPage />} />
          <Route path="/home" element={<Home />} />
          <Route path="/ei" element={<EmployeeInterface />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
