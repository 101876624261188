// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ei-x-button {
  background: linear-gradient(#700, #f00);
  width: 20px;
  height: 20px;
  display: flex;
  align-content: center;
  justify-content: center;
  display: inline-block;
  text-decoration: none;
  color: #fff;
  border-radius: 3px;
  box-shadow: 0px 1px 4px -2px #333;
  text-shadow: 0px -1px #333;
}

.ei-x-button-x {
  margin: auto;
  font-size: 10px;
  font-weight: 100;
  height: min-content;
  width: min-content;
  margin-top: 15%;
}


.ei-x-button:hover {
 transform: scale(1.2);
 cursor: pointer;
}


.ei-x-button:active {
  transform: scale(1.3);
  cursor: pointer;
 }`, "",{"version":3,"sources":["webpack://./src/components/EmployeeInterface/components/XButton.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;EACvC,WAAW;EACX,YAAY;EACZ,aAAa;EACb,qBAAqB;EACrB,uBAAuB;EACvB,qBAAqB;EACrB,qBAAqB;EACrB,WAAW;EACX,kBAAkB;EAClB,iCAAiC;EACjC,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;AACjB;;;AAGA;CACC,qBAAqB;CACrB,eAAe;AAChB;;;AAGA;EACE,qBAAqB;EACrB,eAAe;CAChB","sourcesContent":[".ei-x-button {\n  background: linear-gradient(#700, #f00);\n  width: 20px;\n  height: 20px;\n  display: flex;\n  align-content: center;\n  justify-content: center;\n  display: inline-block;\n  text-decoration: none;\n  color: #fff;\n  border-radius: 3px;\n  box-shadow: 0px 1px 4px -2px #333;\n  text-shadow: 0px -1px #333;\n}\n\n.ei-x-button-x {\n  margin: auto;\n  font-size: 10px;\n  font-weight: 100;\n  height: min-content;\n  width: min-content;\n  margin-top: 15%;\n}\n\n\n.ei-x-button:hover {\n transform: scale(1.2);\n cursor: pointer;\n}\n\n\n.ei-x-button:active {\n  transform: scale(1.3);\n  cursor: pointer;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
