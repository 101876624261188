// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* COLORS */

.sw-color-primary {
    color: #3abea8;
}

.sw-color-primary-light {
    color: #4ae9cf;
}

.sw-color-primary-dark {
    color: #277165;
}

.sw-color-primary-hover:hover {
    transition: 1s ease;
    color: #277165;
}

.sw-color-secondary {
    color: #ee6426;
}

.sw-color-secondary-light {
    color: #ff7e42;
}

.sw-color-secondary-dark {
    color: #893914;
}

.sw-secondary-hover:hover {
    color: #893914;
}

.sw-color-alternate-1 {
    color: #db1010;
}

.sw-color-alternate-1-light {
    color: #fd3232;
}

.sw-color-alternate-1-dark {
    color: #810000;
}

.sw-alternate-1-hover:hover {
    color: #810000;
}
`, "",{"version":3,"sources":["webpack://./src/components/App/Themes.css"],"names":[],"mappings":"AAAA,WAAW;;AAEX;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["/* COLORS */\n\n.sw-color-primary {\n    color: #3abea8;\n}\n\n.sw-color-primary-light {\n    color: #4ae9cf;\n}\n\n.sw-color-primary-dark {\n    color: #277165;\n}\n\n.sw-color-primary-hover:hover {\n    transition: 1s ease;\n    color: #277165;\n}\n\n.sw-color-secondary {\n    color: #ee6426;\n}\n\n.sw-color-secondary-light {\n    color: #ff7e42;\n}\n\n.sw-color-secondary-dark {\n    color: #893914;\n}\n\n.sw-secondary-hover:hover {\n    color: #893914;\n}\n\n.sw-color-alternate-1 {\n    color: #db1010;\n}\n\n.sw-color-alternate-1-light {\n    color: #fd3232;\n}\n\n.sw-color-alternate-1-dark {\n    color: #810000;\n}\n\n.sw-alternate-1-hover:hover {\n    color: #810000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
