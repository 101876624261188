// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


.access-button {
    display: inline-block;
    outline: none;
    cursor: pointer;
    font-weight: 500;
    border: 1px solid transparent;
    border-radius: 2px;
    height: 36px;
    line-height: 34px;
    font-size: 14px;
    color: #ffffff;
    background-color: #42bca9;
    transition: background-color 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
    padding: 0 18px;
  }
  
  .access-button:hover {
    color: #ffffff;
    background-color: #006570;
  }

  .browser-button {
    display: inline-block;
    outline: none;
    cursor: pointer;
    font-weight: 500;
    border: 1px solid transparent;
    border-radius: 2px;
    height: 36px;
    line-height: 34px;
    font-size: 14px;
    color: #ffffff;
    /* background-color: #0d4b52; */
    background-color: #f28322;
    transition: background-color 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
    padding: 0 18px;
  }
  
  .browser-button:hover {
    color: #f88318;
    /* background-color: #31989b; */
    background-color: #ffffff;
  }`, "",{"version":3,"sources":["webpack://./src/components/App/Styles.css"],"names":[],"mappings":";;;AAGA;IACI,qBAAqB;IACrB,aAAa;IACb,eAAe;IACf,gBAAgB;IAChB,6BAA6B;IAC7B,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,cAAc;IACd,yBAAyB;IACzB,6EAA6E;IAC7E,eAAe;EACjB;;EAEA;IACE,cAAc;IACd,yBAAyB;EAC3B;;EAEA;IACE,qBAAqB;IACrB,aAAa;IACb,eAAe;IACf,gBAAgB;IAChB,6BAA6B;IAC7B,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,cAAc;IACd,+BAA+B;IAC/B,yBAAyB;IACzB,6EAA6E;IAC7E,eAAe;EACjB;;EAEA;IACE,cAAc;IACd,+BAA+B;IAC/B,yBAAyB;EAC3B","sourcesContent":["\n\n\n.access-button {\n    display: inline-block;\n    outline: none;\n    cursor: pointer;\n    font-weight: 500;\n    border: 1px solid transparent;\n    border-radius: 2px;\n    height: 36px;\n    line-height: 34px;\n    font-size: 14px;\n    color: #ffffff;\n    background-color: #42bca9;\n    transition: background-color 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;\n    padding: 0 18px;\n  }\n  \n  .access-button:hover {\n    color: #ffffff;\n    background-color: #006570;\n  }\n\n  .browser-button {\n    display: inline-block;\n    outline: none;\n    cursor: pointer;\n    font-weight: 500;\n    border: 1px solid transparent;\n    border-radius: 2px;\n    height: 36px;\n    line-height: 34px;\n    font-size: 14px;\n    color: #ffffff;\n    /* background-color: #0d4b52; */\n    background-color: #f28322;\n    transition: background-color 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;\n    padding: 0 18px;\n  }\n  \n  .browser-button:hover {\n    color: #f88318;\n    /* background-color: #31989b; */\n    background-color: #ffffff;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
