// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Ray1 {
  transform: translateY(35px) scale(2.7) rotate(90deg);
}

#Ray2 {
  transform: translateY(17px) scale(2.7) rotate(90deg);
}

#Ray3 {
  transform: translateY(10px) scale(2.7) rotate(90deg);
}

#Ray4 {
  transform: translateY(17px) scale(2.7) rotate(90deg);
}

#Ray5 {
  transform: translateY(39px) scale(2.7) rotate(90deg);
}

.RayAnimation {
  /* position: absolute; */
  /* z-index: 100; */
  top: 50%;
  left: 50%;
  width: 25px;
  enable-background: new 0 0 316 40;
  transform: rotate(90deg) translateY(-50%) scale(2.7);
  z-index: 10;
}

.ir-logo-ray-group {
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 20px;
}


.ray-path {
  stroke-dasharray: 803px, 245px;
  stroke-dashoffset: 0;
  fill: none;
  /* stroke: hsla(19, 92%, 49%, 0.583); */
  z-index: 10;
  stroke: hsla(0, 92%, 49%, 0.516);
  stroke-width: 6px;
  stroke-linecap: round;
  stroke-miterlimit: 5;
  animation: RayAnimation 1s linear infinite;
}

.sw-ray-rack-group {
  display: flex;
}

.sw-ray-rack-sub-group {
  margin-right: 3px;
}

.RayWrapper {
  position: relative;
  top: 25px;
  height: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/General/Ray.css"],"names":[],"mappings":"AAAA;EACE,oDAAoD;AACtD;;AAEA;EACE,oDAAoD;AACtD;;AAEA;EACE,oDAAoD;AACtD;;AAEA;EACE,oDAAoD;AACtD;;AAEA;EACE,oDAAoD;AACtD;;AAEA;EACE,wBAAwB;EACxB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,WAAW;EACX,iCAAiC;EACjC,oDAAoD;EACpD,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,YAAY;AACd;;;AAGA;EACE,8BAA8B;EAC9B,oBAAoB;EACpB,UAAU;EACV,uCAAuC;EACvC,WAAW;EACX,gCAAgC;EAChC,iBAAiB;EACjB,qBAAqB;EACrB,oBAAoB;EACpB,0CAA0C;AAC5C;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,YAAY;AACd","sourcesContent":["#Ray1 {\n  transform: translateY(35px) scale(2.7) rotate(90deg);\n}\n\n#Ray2 {\n  transform: translateY(17px) scale(2.7) rotate(90deg);\n}\n\n#Ray3 {\n  transform: translateY(10px) scale(2.7) rotate(90deg);\n}\n\n#Ray4 {\n  transform: translateY(17px) scale(2.7) rotate(90deg);\n}\n\n#Ray5 {\n  transform: translateY(39px) scale(2.7) rotate(90deg);\n}\n\n.RayAnimation {\n  /* position: absolute; */\n  /* z-index: 100; */\n  top: 50%;\n  left: 50%;\n  width: 25px;\n  enable-background: new 0 0 316 40;\n  transform: rotate(90deg) translateY(-50%) scale(2.7);\n  z-index: 10;\n}\n\n.ir-logo-ray-group {\n  display: flex;\n  justify-content: center;\n  position: relative;\n  bottom: 20px;\n}\n\n\n.ray-path {\n  stroke-dasharray: 803px, 245px;\n  stroke-dashoffset: 0;\n  fill: none;\n  /* stroke: hsla(19, 92%, 49%, 0.583); */\n  z-index: 10;\n  stroke: hsla(0, 92%, 49%, 0.516);\n  stroke-width: 6px;\n  stroke-linecap: round;\n  stroke-miterlimit: 5;\n  animation: RayAnimation 1s linear infinite;\n}\n\n.sw-ray-rack-group {\n  display: flex;\n}\n\n.sw-ray-rack-sub-group {\n  margin-right: 3px;\n}\n\n.RayWrapper {\n  position: relative;\n  top: 25px;\n  height: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
