import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  Paper,
  Button,
  IconButton,
} from "@mui/material";
import { BsFillGearFill } from "react-icons/bs";
import genSaunaImage from "../components/getSaunaImage";
import Led from "../components/Led";
import { EI_SAUNAS, EI_UNLOCK_ENTRY, SEND_REQUEST } from "../../../sagas/actions";
import requestEISaunas from "../../../modules/api/ei/requestEISaunas";
import requestEIUnlockEntry from "../../../modules/api/ei/requestEIUnlockEntry";

class EmployeeInterfaceHome extends Component {
  componentDidMount() {
    this.refreshSaunas(this.props.tokenValid);
    this.props.refreshTokenStatus();
  }

  refreshSaunas = (tokenValid) => {
    if (tokenValid && this.props.eiSaunas.payload.length === 0) {
      this.props.requestSaunas(localStorage.getItem("token"));
    }
  };

  returnLedClass = (activated, status) => {
    if (!activated) return "led-grey";
    if (status === 200 || status === true) return "led-green";
    return "led-red";
  };

  generateRoomTiles = (rooms, siteId) => {
    return Array.isArray(rooms) ? (
      <Box display="flex" flexWrap="wrap" gap={3}>
        {rooms.map((room, i) => (
          <Paper
            key={i}
            elevation={4}
            onClick={() => {
              this.props.refreshSaunaData(
                siteId,
                room.staffid ?? room.roomid,
                room.name,
                room.saunatype
              );
              this.props.set("view", "room");
            }}
            sx={{
              width: 220,
              p: 2,
              pt: 3,
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              cursor: "pointer",
              borderRadius: 2,
              backgroundColor: "background.paper",
              transition: "box-shadow 0.3s, transform 0.2s",
              "&:hover": {
                boxShadow: 6,
                transform: "translateY(-2px)",
              },
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                borderRadius: "50%",
                p: 0.5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Led clName={this.returnLedClass(room.activated, room.status)} />
            </Box>
            <Box
              component="img"
              src={genSaunaImage(room.saunatype)}
              alt={`Sauna ${room.saunatype}`}
              sx={{ width: "100%", borderRadius: 1, mb: 1 }}
            />
            <Typography variant="subtitle1" fontWeight="bold">
              {room.name}
            </Typography>
          </Paper>
        ))}
      </Box>
    ) : null;
  };



  generateLocations = (locations) => {
    const theme = this.props.theme;

    return Array.isArray(locations)
      ? locations.map((location, i) => (
        <Box key={i} mb={4} p={3} >
          {console.log('location', location)}
          <Paper
            variant="outlined"
            sx={{ p: 2, bgcolor: theme.palette, }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
              flexWrap="wrap"
              gap={2}
            >
              <Box display="flex" alignItems="center" gap={1}>
                <Led clName={this.returnLedClass(true, location.status)} />
                <Typography variant="h6" fontWeight="bold">
                  {location.city}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={2}>
                {location.access_version === "V2" && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      this.props.requestUnlockEntry(
                        location.siteid,
                        location.locationid,
                        "Front Door",
                        localStorage.getItem("token")
                      );
                    }}
                  >
                    UNLOCK DOOR
                  </Button>
                )}
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    console.log('Settings clicked: ', location.siteid, location.locationid, location.city);
                    this.props.refreshLocationData(
                      location.siteid,
                      location.locationid,
                      location.city
                    );
                    this.props.set("view", "location");
                  }}
                >
                  <Typography sx={{ mr: 1 }}>SETTINGS</Typography>
                  <IconButton size="small">
                    <BsFillGearFill />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Box mt={2}>
              {this.generateRoomTiles(location.rooms, location.siteid)}
            </Box>
          </Paper>
        </Box>
      ))
      : null;
  };

  render() {
    return (
      <Box py={3}>
        <Paper mt={3} sx={{ p: 2 }} variant="outlined">
          <Typography
            variant="h5"
            fontWeight="500"
            sx={{
              color: "text.primary",
              letterSpacing: 1,
              pt: 3,
              textTransform: "uppercase",
            }}
          >
            SweatWorks Studio Interface
          </Typography>
          <Box>
            {this.generateLocations(this.props.eiSaunas.payload)}
          </Box>
        </Paper>
      </Box>

    );
  }
}

const mapStateToProps = (state) => ({
  eiSaunas: state.eiSaunas,
  theme: state.theme || {}, // fallback if needed
});

const mapDispatchToProps = (dispatch) => ({
  requestSaunas: async (token) => {
    dispatch({
      type: SEND_REQUEST,
      loadActionType: EI_SAUNAS,
      apiCall: requestEISaunas,
      apiCallParams: { token },
    });
  },
  requestUnlockEntry: async (siteId, locationId, entry, token) => {
    dispatch({
      type: SEND_REQUEST,
      loadActionType: EI_UNLOCK_ENTRY,
      apiCall: requestEIUnlockEntry,
      apiCallParams: { token, siteId, locationId, entry },
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeInterfaceHome);
