// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ei-header-title {
  color: white;
  text-align: center;
  padding-top: 2%;
  padding-bottom: 1%;
}
.ei-room-wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.ei-content {
  color: white;
  width: 100%;
  min-height: 100vh;
  /* height: 100%; */
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
}

.ei-container {
  background: -webkit-linear-gradient(
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.1) 50%,
    /* rgba(0, 0, 0, 0.7) 80%, */ /* rgba(0, 0, 0, 0.2) 90%, */
      /* rgba(0, 0, 0, 0.2) 95%, */ rgba(0, 0, 0, 0) 100%
  );
  width: 100%;
  min-height: 100%;
  background-color: #111413c6;
  background-color: #111413;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.bgColorBlack {
  background: -webkit-linear-gradient(
    rgba(140, 140, 140, 0.5) 0%,
    rgba(0, 0, 0, 0.05) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  height: 100%;
  width: 100%;
}

.employee-interface-title-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  color: white;
  font-weight: 500;
  font-size: xx-large;
  margin-top: 5%;
  margin-bottom: 5%;
}


.ei-flex {
  display: flex;
}

.si-title-icon {
  height: 24px;
  width: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/EmployeeInterface/EmployeeInterface.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE;;;;;GAKC;EACD,WAAW;EACX,gBAAgB;EAChB,2BAA2B;EAC3B,yBAAyB;EACzB,6EAA6E;AAC/E;;AAEA;EACE;;;;GAIC;EACD,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,qBAAqB;EACrB,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;EACd,iBAAiB;AACnB;;;AAGA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":[".ei-header-title {\n  color: white;\n  text-align: center;\n  padding-top: 2%;\n  padding-bottom: 1%;\n}\n.ei-room-wrapper {\n  max-width: 1200px;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.ei-content {\n  color: white;\n  width: 100%;\n  min-height: 100vh;\n  /* height: 100%; */\n  margin-left: auto;\n  margin-right: auto;\n  max-width: 1000px;\n}\n\n.ei-container {\n  background: -webkit-linear-gradient(\n    rgba(0, 0, 0, 0.7) 0%,\n    rgba(0, 0, 0, 0.1) 50%,\n    /* rgba(0, 0, 0, 0.7) 80%, */ /* rgba(0, 0, 0, 0.2) 90%, */\n      /* rgba(0, 0, 0, 0.2) 95%, */ rgba(0, 0, 0, 0) 100%\n  );\n  width: 100%;\n  min-height: 100%;\n  background-color: #111413c6;\n  background-color: #111413;\n  font-family: \"Gill Sans\", \"Gill Sans MT\", Calibri, \"Trebuchet MS\", sans-serif;\n}\n\n.bgColorBlack {\n  background: -webkit-linear-gradient(\n    rgba(140, 140, 140, 0.5) 0%,\n    rgba(0, 0, 0, 0.05) 50%,\n    rgba(0, 0, 0, 0) 100%\n  );\n  height: 100%;\n  width: 100%;\n}\n\n.employee-interface-title-wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-content: center;\n  color: white;\n  font-weight: 500;\n  font-size: xx-large;\n  margin-top: 5%;\n  margin-bottom: 5%;\n}\n\n\n.ei-flex {\n  display: flex;\n}\n\n.si-title-icon {\n  height: 24px;\n  width: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
