// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navBarContainer {
  position: fixed;
  /* top: 50%; */
  /* left: 50%; */
  height: 60px;
  /* bring your own prefixes */
  width: 100%;
  /* transform: translate(-50%, 0%); */
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  /* overflow: hidden; */
  z-index: 100;
  box-shadow: 0 0 10px -4px rgb(0 0 0 / 25%);
  /* margin: auto; */
}

.iconWrapper {
  height: 50px;
  width: fit-content;
}

.access-nav-logo {
  height: 50px;
  width: auto;
}

.StatusIcon {
  color: #ce0b0b;
  height: px;
  width: auto;
}

.accessNavWrapper {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  padding-left: 5%;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Nav/AccessNavBar.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,cAAc;EACd,eAAe;EACf,YAAY;EACZ,4BAA4B;EAC5B,WAAW;EACX,oCAAoC;EACpC,mBAAmB;EACnB,oCAAoC;EACpC,yBAAyB;EACzB,sBAAsB;EACtB,YAAY;EACZ,0CAA0C;EAC1C,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,cAAc;EACd,UAAU;EACV,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".navBarContainer {\n  position: fixed;\n  /* top: 50%; */\n  /* left: 50%; */\n  height: 60px;\n  /* bring your own prefixes */\n  width: 100%;\n  /* transform: translate(-50%, 0%); */\n  /* display: flex; */\n  /* justify-content: space-between; */\n  /* align-items: center; */\n  /* overflow: hidden; */\n  z-index: 100;\n  box-shadow: 0 0 10px -4px rgb(0 0 0 / 25%);\n  /* margin: auto; */\n}\n\n.iconWrapper {\n  height: 50px;\n  width: fit-content;\n}\n\n.access-nav-logo {\n  height: 50px;\n  width: auto;\n}\n\n.StatusIcon {\n  color: #ce0b0b;\n  height: px;\n  width: auto;\n}\n\n.accessNavWrapper {\n  max-width: 800px;\n  margin-left: auto;\n  margin-right: auto;\n  height: 100%;\n  display: flex;\n  padding-left: 5%;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
