// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blob {
  position: relative;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  height: 100%;
  width: 100%;
  transform: scale(1);
  animation: pulse-black 2s infinite;
  background: white;
  box-shadow: 0 0 0 0 #e6510beb;
  animation: pulse 2s infinite;
}

/* @keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #42bca9;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(52, 224, 207, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 224, 207, 0);
  }
} */

@keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 #eb530cfa;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(52, 224, 207, 0);
  }

  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(52, 224, 207, 0);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/General/pulse.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,oCAAoC;EACpC,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,kCAAkC;EAClC,iBAAiB;EACjB,6BAA6B;EAC7B,4BAA4B;AAC9B;;AAEA;;;;;;;;;;;;;;;GAeG;;AAEH;EACE;IACE,qBAAqB;IACrB,6BAA6B;EAC/B;;EAEA;IACE,mBAAmB;IACnB,4CAA4C;EAC9C;;EAEA;IACE,qBAAqB;IACrB,yCAAyC;EAC3C;AACF","sourcesContent":[".blob {\n  position: relative;\n  border-radius: 50%;\n  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);\n  height: 100%;\n  width: 100%;\n  transform: scale(1);\n  animation: pulse-black 2s infinite;\n  background: white;\n  box-shadow: 0 0 0 0 #e6510beb;\n  animation: pulse 2s infinite;\n}\n\n/* @keyframes pulse {\n  0% {\n    transform: scale(0.95);\n    box-shadow: 0 0 0 0 #42bca9;\n  }\n\n  70% {\n    transform: scale(1);\n    box-shadow: 0 0 0 10px rgba(52, 224, 207, 0);\n  }\n\n  100% {\n    transform: scale(0.95);\n    box-shadow: 0 0 0 0 rgba(52, 224, 207, 0);\n  }\n} */\n\n@keyframes pulse {\n  0% {\n    transform: scale(0.9);\n    box-shadow: 0 0 0 0 #eb530cfa;\n  }\n\n  70% {\n    transform: scale(1);\n    box-shadow: 0 0 0 10px rgba(52, 224, 207, 0);\n  }\n\n  100% {\n    transform: scale(0.9);\n    box-shadow: 0 0 0 0 rgba(52, 224, 207, 0);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
