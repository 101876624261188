import { EI_SAUNAS } from "../../sagas/actions";
import { makeLoadingActionTypes } from "../../sagas/loadingSaga";

const initAccessState = { payload: [] };

const { loading, success, failure } = makeLoadingActionTypes(EI_SAUNAS);

const eiSaunas = (state = initAccessState, action) => {
  switch (action.type) {
    case loading:
      return { ...state, isLoading: true, error: null };
    case success:
      return {
        ...state,
        payload: action.payload,
        isLoading: false,
        error: null,
      };
    case failure:
      console.log('eiSaunas failure');
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default eiSaunas;
