// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css2?family=Baloo+Tammudu+2:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baloo+Tammudu+2:wght@500&family=Merriweather:ital@1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zen+Tokyo+Zoo&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Yaldevi&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative&family=Raleway&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@1,500&display=swap');

*/

/* @import url("https://fonts.googleapis.com/css2?family=Work+Sans:&display=swap") */`, "",{"version":3,"sources":["webpack://./src/components/App/fonts.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;CAoBC;;AAED,oFAAoF","sourcesContent":["/* @import url(\"https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap\");\n@import url(\"https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext\");\n@import url(\"https://fonts.googleapis.com/css2?family=Baloo+Tammudu+2:wght@500&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Baloo+Tammudu+2:wght@500&family=Merriweather:ital@1&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Zen+Tokyo+Zoo&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=PT+Sans&display=swap\");\n\n@import url(\"https://fonts.googleapis.com/css2?family=Questrial&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Yaldevi&display=swap\");\n\n@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');\n\n@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative&family=Raleway&display=swap');\n\n@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');\n\n@import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@800&display=swap');\n\n@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@1,500&display=swap');\n\n*/\n\n/* @import url(\"https://fonts.googleapis.com/css2?family=Work+Sans:&display=swap\") */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
