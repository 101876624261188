import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  useTheme,
  Divider,
  Switch,
  FormControlLabel,
} from "@mui/material";
import genSaunaImage from "../components/getSaunaImage";
import { EI_AUTOMATIONS_UPDATE, SEND_REQUEST } from "../../../sagas/actions";
import requestEIAutomationsUpdate from "../../../modules/api/ei/requestEIAutomationsUpdate";
import { BackButton } from "../components/BackButton";
import switchIcon from "../assets/power-switch.png";
import tempIcon from "../assets/heat.png";
import moment from "moment-timezone";
import SensorsOutlinedIcon from '@mui/icons-material/SensorsOutlined'; // make sure this is imported

function RoomViewHeader(props) {
  const theme = useTheme();
  const [saunaAuto, setSaunaAuto] = useState(props.saunaAuto);
  const [lastSavedSaunaAuto, setLastSaunaAuto] = useState(props.saunaAuto);
  const [redLightAuto, setRedLightAuto] = useState(props.redLightAuto);
  const [lastSavedRedLightAuto, setLastRedLightAuto] = useState(props.redLightAuto);
  const [tempGoal, setTempGoal] = useState(props.preheatTempGoal);
  const [maxTemp, setMaxTemp] = useState(props.maxSaunaTemp);
  const [lastTempGoal, setLastTempGoal] = useState(props.preheatTempGoal);
  const [lastMaxTemp, setLastMaxTemp] = useState(props.maxSaunaTemp);

  useEffect(() => {
    setSaunaAuto(props.saunaAuto);
    setRedLightAuto(props.redLightAuto);
    setTempGoal(props.preheatTempGoal);
    setMaxTemp(props.maxSaunaTemp);
  }, [props.saunaAuto, props.redLightAuto, props.preheatTempGoal, props.maxSaunaTemp]);

  const settingsChanged = (curA, curB, lastA, lastB) => curA !== lastA || curB !== lastB;

  const handleAutomationsUpdate = () => {
    props.requestUpdateAutomations(localStorage.getItem("token"), props.siteId, props.staffId, saunaAuto, redLightAuto);
    setLastSaunaAuto(saunaAuto);
    setLastRedLightAuto(redLightAuto);
  };

  const handleTempGoalUpdate = () => {
    props.requestUpdateTempGoal(localStorage.getItem("token"), props.siteId, props.staffId, tempGoal, maxTemp);
    setLastTempGoal(tempGoal);
    setLastMaxTemp(maxTemp);
  };
  console.log('RoomViewHeader props: ', props);
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          mb: 3,
          pt: 3,
          flexWrap: "wrap",
        }}
      >
        <BackButton set={props.set} viewValue="home" />
        <Typography
          variant="h5"
          fontWeight="500"
          sx={{
            color: "white",
            letterSpacing: 1,
            textTransform: "uppercase",
          }}
          flexGrow={1}
        >
          {props.roomName}
        </Typography>
      </Box>
      <Box sx={{ mb: 2, display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 3 }}>
        {/* Header & Room Info */}
        <Paper sx={{ p: 3, border: `1px solid ${theme.palette.divider}`, borderRadius: 2, flex: 1, minWidth: 280 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
            <Box ml="auto" display="flex" alignItems="center" gap={1}>
              {props.status && (
                <Typography
                  sx={{
                    px: 1.5,
                    py: 0.5,
                    borderRadius: 1,
                    bgcolor: props.status === 200 ? "success.main" : "error.main",
                    color: "white",
                    fontWeight: 600,
                  }}
                >
                  {props.status === 200 ? "ONLINE" : "OFFLINE"}
                </Typography>
              )}
            </Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap={1}>
              <SensorsOutlinedIcon sx={{ fontSize: 24 }} color="action" />
              <Typography fontWeight="bold">
                CURRENT STATUS
              </Typography>
            </Box>

            <Typography
              variant="body2"
              fontStyle="italic"
              fontWeight={400}
              color="text.secondary"
              mb={1}
              ml="auto"
            >
              {moment(props.currentData.date_time).format("h:mm a (MM/DD/YYYY)")}
            </Typography>
          </Box>
          <Divider sx={{ mb: 2 }} />
          <Box sx={{ display: "flex", alignItems: "flex-start", gap: 3, flexWrap: "wrap" }}>
            <img
              src={genSaunaImage(props.saunaType)}
              alt={`Sauna ${props.saunaType}`}
              style={{ width: 150, borderRadius: 8 }}
            />
            {props.version === 1 && ( //If Version 1 show the default data
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1, flex: 1 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
                  <Typography fontWeight="medium">Temp:</Typography>
                  <Typography>{props.temp ? `${Math.round(props.temp)}°` : "N/A"}</Typography>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
                  <Typography fontWeight="medium">Preheating:</Typography>
                  <Typography color={props.preheatMode ? "success.main" : "text.primary"}>
                    {props.preheatMode ? "ENGAGED" : "NOT ACTIVE"}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
                  <Typography fontWeight="medium">Red Light:</Typography>
                  <Typography color={props.redLightStatus ? "success.main" : "text.primary"}>
                    {props.redLightStatus ? "ENGAGED" : "NOT ON"}
                  </Typography>
                </Box>
              </Box>
            )}
            {props.version === 2 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  flex: 1,
                  border: "2px solid",
                  borderColor: props.currentData.power_on
                    ? theme.palette.primary.main
                    : theme.palette.grey[400],
                  borderRadius: 2,
                  p: 2,
                }}
              >
                {console.log('VERSION 2 DETECTED!')}
                <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
                  <Typography fontWeight="medium">Power On (Display is on)</Typography>
                  <Typography color={props.currentData.power_on ? "text.primary" : "error"}>
                    {props.currentData.power_on ? "ON" : "OFF"}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
                  <Typography fontWeight="medium">Actual Temp</Typography>
                  <Typography>
                    {props.currentData.actual_temperature_f}
                    <Typography
                      component="span"
                      variant="caption"
                      sx={{ ml: 0.5, verticalAlign: "super" }}
                    >
                      °{props.currentData.is_celsius ? "C" : "F"}
                    </Typography>
                  </Typography>
                </Box>


                <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
                  <Typography fontWeight="medium">Temp Set to</Typography>
                  <Typography>
                    {props.currentData.set_temperature_f}
                    <Typography
                      component="span"
                      variant="caption"
                      sx={{ ml: 0.5, verticalAlign: "super" }}
                    >
                      °{props.currentData.is_celsius ? "C" : "F"}
                    </Typography>
                  </Typography>
                </Box>


                <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
                  <Typography fontWeight="medium">Timer Set to</Typography>
                  <Typography>{props.currentData.time_remaining_minutes} min</Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Paper>
        <Box display="flex" flexDirection="column" gap={2} sx={{ minWidth: 280 }}>

          <Paper
            sx={{
              p: 3,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 2,
              flex: 1,
              minWidth: 280,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 2,
              }}
            >
              <Box display="flex" alignItems="center" gap={1}>
                <img src={switchIcon} alt="Automation" style={{ width: 24 }} />
                <Typography fontWeight="bold">Automation Controls</Typography>
              </Box>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <Typography variant="body2" textAlign="left" color="text.secondary" mb={2}>
              Disable either service to stop future automations for this room.
            </Typography>

            <Box display="flex" flexDirection="column" gap={2} mb={2}>
              <FormControlLabel
                control={
                  <Switch
                    checked={saunaAuto}
                    onChange={() => setSaunaAuto(!saunaAuto)}
                  />
                }
                label="Sauna"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={redLightAuto}
                    onChange={() => setRedLightAuto(!redLightAuto)}
                  />
                }
                label="Red Light"
              />
            </Box>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={
                !settingsChanged(
                  saunaAuto,
                  redLightAuto,
                  lastSavedSaunaAuto,
                  lastSavedRedLightAuto
                )
              }
              onClick={handleAutomationsUpdate}
            >
              Update
            </Button>
          </Paper>
          {props.preheatTempGoal && props.maxSaunaTemp && (
            <Paper sx={{ p: 3, border: `1px solid ${theme.palette.divider}`, borderRadius: 2, flex: 1, minWidth: 280 }}>
              <Box display="flex" alignItems="center" gap={1} mb={2}>
                <img src={tempIcon} alt="Temperature" style={{ width: 24 }} />
                <Typography fontWeight="bold">Preheat Temperature</Typography>
              </Box>
              <Typography variant="body2" mb={2}>
                Set the temperature that automation will try to reach.
              </Typography>

              <Box display="flex" gap={2} flexWrap="wrap" mb={2}>
                <TextField
                  type="number"
                  label="Preheat Goal (°F)"
                  value={tempGoal}
                  onChange={(e) => setTempGoal(e.target.value)}
                  size="small"
                />
                <TextField
                  type="number"
                  label="Max Temp (°F)"
                  value={maxTemp}
                  onChange={(e) => setMaxTemp(e.target.value)}
                  size="small"
                />
              </Box>

              <Button
                variant="contained"
                color="primary"
                disabled={!settingsChanged(Number(tempGoal), Number(maxTemp), lastTempGoal, lastMaxTemp)}
                onClick={handleTempGoalUpdate}
              >
                Update
              </Button>
            </Paper>
          )}
        </Box>
      </Box>
    </Box>
  );
}


const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  requestUpdateAutomations: (token, siteId, staffId, saunaAuto, redLightAuto) => {
    dispatch({
      type: SEND_REQUEST,
      loadActionType: EI_AUTOMATIONS_UPDATE,
      apiCall: requestEIAutomationsUpdate,
      apiCallParams: {
        route: "enabling",
        token,
        siteId,
        staffId,
        data: { sauna: saunaAuto, redLight: redLightAuto },
      },
    });
  },
  requestUpdateTempGoal: (token, siteId, staffId, tempGoal, maxTemp) => {
    dispatch({
      type: SEND_REQUEST,
      loadActionType: EI_AUTOMATIONS_UPDATE,
      apiCall: requestEIAutomationsUpdate,
      apiCallParams: {
        route: "temp_goal",
        token,
        siteId,
        staffId,
        data: { tempGoal: Number(tempGoal), maxTemp: Number(maxTemp) },
      },
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RoomViewHeader);
