import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const DropDownMenu = ({ dayQuarter, set }) => {
  const options = [
    "12:00 AM - 6:00 AM",
    "6:00 AM - 12:00 PM (Noon)",
    "12:00 PM - 6:00 PM",
    "6:00 PM - 12:00 AM",
  ];

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="dayQuarter-label">Time Frame</InputLabel>
      <Select
        labelId="dayQuarter-label"
        value={dayQuarter - 1}
        label="Time Frame"
        onChange={(e) => set("dayQuarter", Number(e.target.value) + 1)}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={index}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropDownMenu;
