// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0px;
}

.pac-target-input {
  margin-top: 5px;
  margin-right: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Map/google.css"],"names":[],"mappings":"AAAA;EACE,qFAAqF;;EAErF,iCAAiC;EACjC,WAAW;AACb;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".pac-container:after {\n  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */\n\n  background-image: none !important;\n  height: 0px;\n}\n\n.pac-target-input {\n  margin-top: 5px;\n  margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
