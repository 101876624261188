import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export const BackButton = ({ set, viewValue }) => {
  return (
    <Tooltip title="Go Back">
      <IconButton
        onClick={() => set("view", viewValue)}
        color="primary"
        sx={{
          width: 36,
          height: 36,
          border: "1px solid",
          borderColor: "primary.main",
          borderRadius: 2,
        }}
      >
        <ArrowBackIosNewIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};
