import React from "react";
import { connect } from "react-redux";
import { Box, Typography, Paper } from "@mui/material";
import LoadingWrapper from "../../General/LoadingWrapper";
import LocationSchedule from "./LocationSchedule";
import LocationScheduleExceptions from "./LocationScheduleExceptions";
import { BackButton } from "../components/BackButton";

const EmployeeInterfaceLocationView = ({ eiLocationData, set, siteId, locationId, locationName, refreshLocationData }) => {
  console.log('eiLocationData', eiLocationData);
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          mb: 3,
          pt: 3,
          flexWrap: "wrap",
        }}
      >
        <BackButton set={set} viewValue="home" />
        <Typography
          variant="h5"
          fontWeight="500"
          sx={{
            color: "white",
            letterSpacing: 1,
            textTransform: "uppercase",
          }}
          flexGrow={1}
        >
          LOCATION SETTINGS
        </Typography>
      </Box>
      <LoadingWrapper
        isLoading={eiLocationData?.isLoading}
        isFailure={eiLocationData?.error}
        isSuccess={eiLocationData?.payload}
        showChildrenOnLoading={false}
        showCheckmarkOnSuccess={false}
        showOverlay={true}
        minHeight={"100%"}
        minWidth={"100%"}
        height={20}
        width={20}
        iconSize={20}
      >

        <Paper sx={{ p: 2, mb: 3 }} variant="outlined">
          <LocationSchedule
            locationId={locationId}
            locationName={locationName}
            siteId={siteId}
          />
        </Paper>

        <Paper sx={{ p: 2, pb: 70 }} variant="outlined"
        >
          <LocationScheduleExceptions
            locationId={locationId}
            locationName={locationName}
            refreshLocationData={refreshLocationData}
            siteId={siteId}
          />
        </Paper>
      </LoadingWrapper>
    </Box>
  );
};

const mapStateToProps = (reduxState) => ({
  eiLocationData: reduxState.eiLocationData,
});

export default connect(mapStateToProps)(EmployeeInterfaceLocationView);
