// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 32px;
  height: 32px;
  /* background-color: #000; */
  /* border: 2px solid #fff; */
  border-radius: 100%;
  -webkit-user-select: none;
          user-select: none;
  transform: translate(-50%, -50%);
  &:hover {
    z-index: 1;
  }
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Map/Marker.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,WAAW;EACX,YAAY;EACZ,4BAA4B;EAC5B,4BAA4B;EAC5B,mBAAmB;EACnB,yBAAiB;UAAjB,iBAAiB;EACjB,gCAAgC;EAChC;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,0BAA0B;IAC1B,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,0BAA0B;IAC1B,UAAU;EACZ;AACF;;AAEA;EACE;IACE,UAAU;IACV,6CAA6C;EAC/C;;EAEA;IACE,UAAU;IACV,0CAA0C;EAC5C;;EAEA;IACE,2CAA2C;EAC7C;;EAEA;IACE,uCAAuC;EACzC;AACF","sourcesContent":[".marker {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  width: 32px;\n  height: 32px;\n  /* background-color: #000; */\n  /* border: 2px solid #fff; */\n  border-radius: 100%;\n  user-select: none;\n  transform: translate(-50%, -50%);\n  &:hover {\n    z-index: 1;\n  }\n}\n\n@keyframes pulsate {\n  0% {\n    transform: scale(0.1, 0.1);\n    opacity: 0;\n  }\n\n  50% {\n    opacity: 1;\n  }\n\n  100% {\n    transform: scale(1.2, 1.2);\n    opacity: 0;\n  }\n}\n\n@keyframes bounce {\n  0% {\n    opacity: 0;\n    transform: translateY(-2000px) rotate(-45deg);\n  }\n\n  60% {\n    opacity: 1;\n    transform: translateY(30px) rotate(-45deg);\n  }\n\n  80% {\n    transform: translateY(-10px) rotate(-45deg);\n  }\n\n  100% {\n    transform: translateY(0) rotate(-45deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
