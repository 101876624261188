// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel.carousel-slider {
    overflow: inherit;
}

.carousel .control-next.control-arrow, .carousel .control-next.control-arrow:hover{
    background-color: transparent;
    right: -146px;
}

.carousel .control-prev.control-arrow, .carousel .control-prev.control-arrow:hover {
    background-color: transparent;
    left: -146px;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow{
    opacity: 1;
}

.carousel .control-next.control-arrow:before {
    content: '';
    border: solid #0135AD;
    border-width: 0 8px 8px 0;
    display: inline-block;
    padding: 14px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.carousel .control-prev.control-arrow:before {
    content: '';
    border: solid #0135AD;
    border-width: 0 8px 8px 0;
    display: inline-block;
    padding: 14px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}`, "",{"version":3,"sources":["webpack://./src/components/Reviews/sw-carousel.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,6BAA6B;IAC7B,aAAa;AACjB;;AAEA;IACI,6BAA6B;IAC7B,YAAY;AAChB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;IACX,qBAAqB;IACrB,yBAAyB;IACzB,qBAAqB;IACrB,aAAa;IACb,yBAAyB;IACzB,iCAAiC;AACrC;;AAEA;IACI,WAAW;IACX,qBAAqB;IACrB,yBAAyB;IACzB,qBAAqB;IACrB,aAAa;IACb,yBAAyB;IACzB,iCAAiC;AACrC","sourcesContent":[".carousel.carousel-slider {\n    overflow: inherit;\n}\n\n.carousel .control-next.control-arrow, .carousel .control-next.control-arrow:hover{\n    background-color: transparent;\n    right: -146px;\n}\n\n.carousel .control-prev.control-arrow, .carousel .control-prev.control-arrow:hover {\n    background-color: transparent;\n    left: -146px;\n}\n\n.carousel .control-arrow, .carousel.carousel-slider .control-arrow{\n    opacity: 1;\n}\n\n.carousel .control-next.control-arrow:before {\n    content: '';\n    border: solid #0135AD;\n    border-width: 0 8px 8px 0;\n    display: inline-block;\n    padding: 14px;\n    transform: rotate(-45deg);\n    -webkit-transform: rotate(-45deg);\n}\n\n.carousel .control-prev.control-arrow:before {\n    content: '';\n    border: solid #0135AD;\n    border-width: 0 8px 8px 0;\n    display: inline-block;\n    padding: 14px;\n    transform: rotate(135deg);\n    -webkit-transform: rotate(135deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
