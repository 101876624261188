import React, { Component } from "react";
import moment from "moment-timezone";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Button,
  Paper,
} from "@mui/material";
import { EI_REQUEST_ADD_SCHEDULE_EXCEPTION, SEND_REQUEST } from "../../../sagas/actions";
import eiAddLocationScheduleException from "../../../modules/api/ei/eiAddLocationScheduleException";
import eiRemoveLocationScheduleException from "../../../modules/api/ei/eiRemoveLocationScheduleException";
import XButton from "../components/XButton";

class LocationScheduleExceptions extends Component {
  state = {
    date: moment().format("MM/DD/YYYY"),
    open: "9:00 am",
    close: "9:00 pm",
  };

  handleAddException = (locationId, date, open, close) => {
    const validTime = (time) =>
      moment(time, "hh:mm a", true).isValid() ||
      moment(time, "h:mm a", true).isValid() ||
      time === "0:00 am";

    if (validTime(open) && validTime(close) && moment(date, "MM/DD/YYYY").isValid()) {
      const token = localStorage.getItem("token");
      this.props.requestAddSchedException(this.props.siteId, locationId, token, date, open, close);
      this.props.refreshLocationData(this.props.siteId, locationId, this.props.locationName);
    } else {
      console.warn("Invalid timestamps for exception");
    }
  };

  handleRemoveException = (locationId, date) => {
    if (moment(date, "MM/DD/YYYY").isValid()) {
      const token = localStorage.getItem("token");
      this.props.requestRemoveSchedException(this.props.siteId, locationId, token, date);
      this.props.refreshLocationData(this.props.siteId, locationId, this.props.locationName);
    }
  };

  render() {
    const { date, open, close } = this.state;
    const { exceptions, locationId } = this.props;

    return (
      <Box>
        <Box>
          <Typography variant="h6" fontWeight="bold" mb={1}>
            Create New Schedule Exception
          </Typography>
          <Typography variant="body2" color="text.secondary" textAlign="left" mb={2}>
            Use this to override the regular weekly schedule for a specific day — for example, to close or adjust hours for holidays, maintenance, or special events.
          </Typography>
        </Box>

        <Paper variant="outlined" sx={{ p: 2, mb: 4 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Open</TableCell>
                <TableCell>Close</TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>Set as Closed</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <TextField
                    size="small"
                    value={date}
                    onChange={(e) => this.setState({ date: e.target.value })}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    value={open}
                    onChange={(e) => this.setState({ open: e.target.value })}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    value={close}
                    onChange={(e) => this.setState({ close: e.target.value })}
                  />
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    onClick={() => this.setState({ open: "0:00 am", close: "0:00 am" })}
                  >
                    CLOSED
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                this.handleAddException(locationId, date, open, close)
              }
            >
              ADD
            </Button>
          </Box>
        </Paper>

        <Typography variant="h6" fontWeight="bold" mb={2}>
          Upcoming Schedule Exceptions
        </Typography>

        <Paper variant="outlined">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Open</TableCell>
                <TableCell>Close</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {exceptions?.map((ex, i) => (
                <TableRow key={i}>
                  <TableCell>{ex.schedule_date}</TableCell>
                  <TableCell>{ex.schedule_open}</TableCell>
                  <TableCell>{ex.schedule_close}</TableCell>
                  <TableCell align="right">
                    <XButton
                      onClick={() =>
                        this.handleRemoveException(locationId, ex.schedule_date)
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  exceptions: state.eiLocationData?.payload?.exceptions,
});

const mapDispatchToProps = (dispatch) => ({
  requestAddSchedException: async (siteId, locationId, token, date, open, close) => {
    dispatch({
      type: SEND_REQUEST,
      loadActionType: EI_REQUEST_ADD_SCHEDULE_EXCEPTION,
      apiCall: eiAddLocationScheduleException,
      apiCallParams: { siteId, locationId, token, date, open, close },
    });
  },
  requestRemoveSchedException: async (siteId, locationId, token, date) => {
    dispatch({
      type: SEND_REQUEST,
      loadActionType: EI_REQUEST_ADD_SCHEDULE_EXCEPTION,
      apiCall: eiRemoveLocationScheduleException,
      apiCallParams: { siteId, locationId, token, date },
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationScheduleExceptions);
