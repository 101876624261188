import React from "react";
import { connect } from "react-redux";
import { Box, Paper } from "@mui/material";
import EiLoginView from "./EILoginView";
import EiSignUpView from "./EISignUpView";
import sweatworkPic from "../../../images/sweatworks_logo_transparent_bg.png";

function EmployeeInterfaceLogin(props) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      sx={{ minHeight: "100vh", backgroundColor: "background.default", p: 2 }}
    >
      <Paper
        elevation={6}
        sx={{
          p: 10,
          m: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Box
          component="img"
          src={sweatworkPic}
          alt="SweatWorks Studio Welcome Header"
          sx={{
            width: 280,
            mb: 3,
          }}
        />
        {props.loginView === "signUp" ? (
          <EiSignUpView set={props.set} />
        ) : (
          <EiLoginView set={props.set} refreshTokenStatus={props.refreshTokenStatus} />
        )}
      </Paper>
    </Box>
  );
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

export default connect(mapStateToProps)(EmployeeInterfaceLogin);
